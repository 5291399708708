import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import Active from './Active';
import ReferFriends from './ReferFriends';
import TotalIncome from './TotalIncome';
import GetPremiumBackdrop from '@/components/GetPremium';
import PermanentDrawerLeft from '@/components/Layouts/Dashboard/User';
export default function GeneralInfo({ openBuyButton }) {
  const [currentSession, setCurrentSession] = useState(null);

  useEffect(() => {
    const session = localStorage && JSON.parse(localStorage.getItem('sessionMitox'));
    setCurrentSession(session);
  }, []);

  if (!currentSession) {
    return <></>;
  } else if (
    currentSession.user.userSubscription === undefined ||
    (currentSession.user.userSubscription && currentSession.user.userSubscription?.length === 0) ||
    currentSession.user.userSubscription[0].status_pay.academy_access === 0
  ) {
    return (
      <>
        <PermanentDrawerLeft session={currentSession}>
          <GetPremiumBackdrop session={currentSession} />
        </PermanentDrawerLeft>
      </>
    );
  } else {
    return (
      <>
        <Grid container spacing={3}>
          {/* In case user not premium */}
          {/* <GetPremiumBackdrop session={currentSession && currentSession} /> */}

          <Grid item xs={12} md={4}>
            <TotalIncome />
          </Grid>
          <Grid item xs={12} md={4}>
            <ReferFriends />
          </Grid>
          <Grid item xs={12} md={4}>
            <Active session={currentSession} openBuyButton={openBuyButton} />
          </Grid>
        </Grid>
      </>
    );
  }
}
