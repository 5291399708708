import { axiosBase } from './axiosBase';

const getAllRanges = async token => {
  const resp = await axiosBase('ranges', 'GET', null, token);
  return resp;
};

const updateRanges = async token => {
  const resp = await axiosBase('users/assignRangeAllUsers', 'GET', null, token);
  return resp;
};

export { getAllRanges, updateRanges };
