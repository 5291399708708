import { axiosBase } from './axiosBase';

const changeMetaTrader = async (body, token) => {
  const resp = await axiosBase('users/changeMetatrader', 'PUT', body, token);
  return resp;
};

const getDataWithMetatrader = async (body, token) => {
  const resp = await axiosBase(`users/metatrader/${body}`, 'GET', null, token);
  return resp;
};

const getAllCommissionsMetatrader = async token => {
  const resp = await axiosBase('subscription/commissionsAll-metatrader', 'GET', null, token);
  return resp;
};

const getCommissionsMetatrader = async token => {
  const resp = await axiosBase('subscription/commissions-metatrader', 'GET', null, token);
  return resp;
};

const executeSplit = async token => {
  const resp = await axiosBase('subscription/splitpayments-metatrader', 'GET', null, token);
  return resp;
};

export {
  changeMetaTrader,
  getDataWithMetatrader,
  getAllCommissionsMetatrader,
  executeSplit,
  getCommissionsMetatrader,
};
