const ranges = [
  {
    id: 1,
    img: '/images/bannerRanges/member.png',
  },
  {
    id: 2,
    img: '/images/bannerRanges/investor.png',
  },
  {
    id: 3,
    img: '/images/bannerRanges/builder.png',
  },
  {
    id: 4,
    img: '/images/bannerRanges/senior.png',
  },
  {
    id: 5,
    img: '/images/bannerRanges/executive.png',
  },
  {
    id: 6,
    img: '/images/bannerRanges/manager.png',
  },
  {
    id: 7,
    img: '/images/bannerRanges/master.png',
  },
  {
    id: 8,
    img: '/images/bannerRanges/royalMaster.png',
  },
  {
    id: 9,
    img: '/images/bannerRanges/ambassador.png',
  },
  {
    id: 10,
    img: '/images/bannerRanges/imperial.png',
  },
  {
    id: 11,
    img: '/images/bannerRanges/king/queen.png',
  },
];

export { ranges };
