export default function sumCommissionsByMonth(arr) {
  const currentMonth = new Date().getMonth();
  const previousMonth = new Date().setMonth(currentMonth - 1);

  let currentMonthCommissions = 0;
  let previousMonthCommissions = 0;

  const filteredTransactions = arr.filter(obj => {
    const transactionDate = new Date(obj.commissions_payed);
    const transactionMonth = transactionDate.getMonth();
    return transactionMonth === currentMonth || transactionMonth === previousMonth;
  });

  filteredTransactions.forEach(obj => {
    const transactionMonth = new Date(obj.commissions_payed).getMonth();
    if (transactionMonth === currentMonth) {
      currentMonthCommissions += Number(obj.amount_commission_usdt);
    } else if (transactionMonth === previousMonth) {
      previousMonthCommissions += Number(obj.amount_commission_usdt);
    }
  });

  return [currentMonthCommissions, previousMonthCommissions];
}
