import CardLayout from './CardLayout';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useEffect, useMemo, useState } from 'react';
import { getAllCommissions } from '@/API/commissions';
import { LoadingButton } from '@mui/lab';
import { Paper } from '@mui/material';
import sumCommissionsByMonth from '@/utils/sumCommissionsMonth';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import VIPImage from '../../../public/images/vip.png';
import VIPImageBuy from '../../../public/images/vip-comprar.png';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

const colors = {
  premium: {
    primary: '#F7A429',
  },
  basic: {
    primary: '#017FC7',
  },
};

export default function Active({ session, openBuyButton }) {
  const { t } = useTranslation('dashboard');
  const [gains, setGains] = useState([0, 0]);

  const isVIP = useMemo(
    () =>
      session?.user?.userSubscription?.some(
        sub => sub.subscription === 'Premium Plus' && sub.status_pay === 1
      ),
    [session]
  );

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem('sessionMitox'));
    (async () => {
      const resp = await getAllCommissions(session.token);
      if (resp.status === 200) {
        const commissions = sumCommissionsByMonth(resp.data);
        setGains([commissions[0], commissions[1]]);
      }
    })();
  }, []);

  const cardProps = isVIP
    ? { contentStyle: { padding: 0 }, ContentSx: { p: 0 } }
    : { style: { display: 'flex', flexDirection: 'column', justifyContent: 'center' } };

  return (
    <>
      <CardLayout {...cardProps}>
        <Typography
          align='center'
          fontWeight={600}
          color='textPrimary'
          marginTop={2}
          variant={'h5'}
        >
          {t('generalInfo.active.title')}
        </Typography>
        <Grid container padding={2}>
          <Grid
            item
            xs={12}
            lg={6}
            display='flex'
            justifyContent='center'
            gap={1}
            sx={{ ...(isVIP ? {} : { flexDirection: 'column' }) }}
          >
            <Typography
              fontWeight={600}
              align='center'
              color='textPrimary'
              fontSize={isVIP ? 18 : 24}
            >
              {t('generalInfo.active.currentMonth')}:
            </Typography>
            <Typography
              color='textPrimary'
              fontWeight={600}
              align='center'
              fontSize={isVIP ? 18 : 32}
            >
              ${gains[0]}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            display='flex'
            justifyContent='center'
            gap={1}
            sx={{ ...(isVIP ? {} : { flexDirection: 'column' }) }}
          >
            <Typography
              fontWeight={600}
              align='center'
              color='textPrimary'
              fontSize={isVIP ? 18 : 24}
            >
              {t('generalInfo.active.lastMonth')}:
            </Typography>
            <Typography
              color='textPrimary'
              fontWeight={600}
              align='center'
              fontSize={isVIP ? 18 : 32}
            >
              ${gains[1] ?? '0'}
            </Typography>
          </Grid>
        </Grid>
        
        {isVIP ? (
          <Image src={VIPImage} style={{ height: 'auto', width: '100%', borderRadius: 16 }} />
        ):(<Grid item xs={12} md={12} style={{justifyContent: 'center', alignItems: 'center'}}>
          <Image src={VIPImageBuy} onClick={() => openBuyButton()} style={{ height: 'auto', width: '100%', borderRadius: 16 }} />
          <br /><br />
          <LoadingButton
            loading={false}
            onClick={() => openBuyButton()}
            sx={{
              backgroundColor: colors.basic.primary,
            }}
            variant='contained'
            startIcon={<WorkspacePremiumIcon />}
          >
            {'Get VIP Founder Club'}
          </LoadingButton>
        </Grid>)}
      </CardLayout>
    </>
  );
}
