import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { useState } from 'react';
import MetaTraderFormFill from './ModalMetaTrader';
import { useTranslation } from 'react-i18next';

const FillFormBtn = styled(Button)(({ theme }) => ({
  width: '50%',
  margin: 'auto',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export default function FormMetatrader({ session }) {
  const { t } = useTranslation('dashboard');

  const [openForm, setOpenForm] = useState(false);
  return (
    <>
      <Container sx={{ my: 4 }} align='center' maxWidth='lg' fullWidth>
        <FillFormBtn
          disabled={session.user.id_metatrader}
          onClick={() => {
            console.log(session);
            setOpenForm(true);
          }}
          sx={{ width: '40%', margin: 'auto' }}
          variant='contained'
          color='primary'
        >
          {session.user.id_metatrader
            ? t('formMetaTrader.editCopy')
            : t('formMetaTrader.completeCopy')}
        </FillFormBtn>
        <MetaTraderFormFill
          session={session}
          open={openForm}
          handleClose={() => setOpenForm(false)}
        />
      </Container>
    </>
  );
}
