export default function sumCommissionByStatus(arr) {
  let sumPaid = 0;
  let sumUnpaid = 0;

  arr.forEach(obj => {
    if (obj.status_pay === 1) {
      sumPaid += Number(obj.amount_commission_usdt);
    } else if (obj.status_pay === 0) {
      sumUnpaid += Number(obj.amount_commission_usdt);
    }
  });

  return [sumPaid, sumUnpaid]; // return the paidCommissions and the unpaidCommissions
}
