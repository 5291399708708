import { Card, CardContent } from '@mui/material';

export default function CardLayout({ children, style, ContentSx, contentStyle }) {
  return (
    <>
      <Card
        variant='elevation'
        elevation={0}
        sx={{
          maxWidth: '800px',
          borderRadius: '1rem',
          border: '1px solid #E1E1E1',
          height: '100%',
        }}
        style={style}
      >
        <CardContent style={contentStyle} sx={{ p: 4, ...ContentSx }}>
          {children}
        </CardContent>
      </Card>
    </>
  );
}
