import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function timeToPayment(paymentDate) {
  const now = new Date();
  const dateToPay = new Date();
  const [hours, minutes, seconds] = paymentDate.split(':').map(Number);

  // Si la hora de pago ya ha pasado, sumamos un día completo
  if (now.getHours() >= hours) {
    dateToPay.setDate(now.getDate() + 1);
  } else {
    dateToPay.setDate(now.getDate());
  }

  dateToPay.setHours(hours, minutes, seconds, 0);

  const minutesDiff = Math.floor((dateToPay - now) / 60000);
  let days = Math.floor(minutesDiff / 1440);
  days = days < 0 ? 0 : days; // Evitamos que se devuelvan días negativos

  let hoursLeft = Math.floor((minutesDiff % 1440) / 60);
  hoursLeft = hoursLeft < 0 ? 0 : hoursLeft; // Evitamos que se devuelvan horas negativas

  const minutesLeft = Math.floor(minutesDiff % 60);
  return [days !== 0 ? days - 1 : days, hoursLeft, minutesLeft];
}

const CounterItem = ({ time, type }) => {
  const timeToFormat = time.toString();

  const formatTime = timeToFormat => {
    if (timeToFormat.length === 1) {
      return [0, timeToFormat[0]];
    } else if (timeToFormat.length === 2) {
      return [timeToFormat[0], timeToFormat[1]];
    }
  };

  const arrTime = formatTime(timeToFormat, type);
  return (
    <>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{
              backgroundColor: 'white',
              px: 2,
              py: 1,
              mx: 0.3,
              borderRadius: '.5rem',
              fontSize: '2rem',
            }}
          >
            {arrTime[0]}
          </Box>
          <Box
            sx={{
              backgroundColor: 'white',
              px: 2,
              py: 1,
              mx: 0.3,
              borderRadius: '.5rem',
              fontSize: '2rem',
            }}
          >
            {arrTime[1]}
          </Box>
        </Box>
        <Box sx={{ mt: 1, color: '#4A4A4A' }}>{type}</Box>
      </Grid>
    </>
  );
};

const GridContainerPayment = styled(Grid)(({ theme }) => ({}));

export default function Counter({ timePay }) {
  const { t } = useTranslation('dashboard');
  const [timeRest, setTimeRest] = useState(timeToPayment(timePay));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRest(timeToPayment(timePay));
    }, 60000);
    console.log(timeRest);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timePay]);

  return (
    <>
      <GridContainerPayment container spacing={3}>
        <Grid item xs={4}>
          <CounterItem time={timeRest[0]} type={t('upcomingPayment.counter.days')} />
        </Grid>
        <Grid item xs={4}>
          <CounterItem time={timeRest[1]} type={t('upcomingPayment.counter.hours')} />
        </Grid>
        <Grid item xs={4}>
          <CounterItem time={timeRest[2]} type={t('upcomingPayment.counter.minutes')} />
        </Grid>
      </GridContainerPayment>
    </>
  );
}
