import $ from 'jquery';
//import dynamic from 'next/dynamic';
import { bannerImages } from './bannerImages';
import styled from '@emotion/styled';
import Image from 'next/legacy/image';
import { useEffect } from 'react';
import { Box, Container, Grid, useMediaQuery } from '@mui/material';
//import 'owl.carousel/dist/assets/owl.carousel.css';
//import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useRouter } from 'next/router';

/*const OwlCarousel = dynamic(import('react-owl-carousel'), {
  ssr: false,
});*/

const ImageContainer = styled(Image)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    height: '200px',
    border: '1px solid red',
  },
}));

const MainContainer = styled(Box)(({ theme }) => ({}));

export default function BannerDashboard() {
  const router = useRouter();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  /*useEffect(() => {
    dynamic(() => import('react-owl-carousel'), {
      ssr: false,
    });
    setTimeout(() => {
      $(document).ready(function () {
        const owl = $('.owl-carousel');

        owl.children().each(function (index) {
          $(this).attr('data-position', index);
        });

        $('.owl-carousel').owlCarousel({
          center: true,
          autoplay: true,
          autoplayTimeout: 3500,
          loop: true,
          autoplayHoverPause: true,
          items: 1,
          dots: false,
          startPosition: 2,
          autoplaySpeed: 900,
          navSpeed: 600,
          responsive: {
            0: {
              items: 1,
            },
            500: {
              items: 1,
            },
            1024: {
              items: 1,
            },
          },
        });
      });
    }, 800);
  }, []);*/

  const handleRedirect = link => {
    if (link !== 'none') {
      window.open(link, '_BLANK');
    }
  };

  console.log({ isMobile });

  return (
    <>
      <Box>
        {bannerImages.map(img => (
          <Box className='item' key={img.id}>
            <ImageContainer
              onClick={() => handleRedirect(img.linkTo ?? 'none')}
              src={img.src}
              alt={img.alt}
              layout='responsive'
              width={1000}
              height={isMobile ? 200 : 100}
            />
          </Box>
        ))}
      </Box>
    </>
  );
}
