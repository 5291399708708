import { Box, Grid, Typography } from '@mui/material';
import Image from 'next/image';
import CardLayout from './CardLayout';
import { useEffect, useState } from 'react';
import { getAllCommissions } from '@/API/commissions';
import getMonthAndYear from '@/utils/getMonthAndYear';
import sumAmountCommissions from '@/utils/sumAmountCommissions';
import { useTranslation } from 'react-i18next';

export default function TotalIncome() {
  const { t } = useTranslation('dashboard');
  const [gains, setGains] = useState([0, 0]);
  const [porcent, setPorcent] = useState(0);

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem('sessionMitox'));
    (async () => {
      const resp = await getAllCommissions(session.token);
      if (resp.status === 200) {
        const gainsFun = sumAmountCommissions(resp.data);
        setGains([gainsFun[0], gainsFun[1]]);

        // porcent of income
        const currentMonth = getMonthAndYear(new Date(), 0);
        const currentMonthCommissions = resp.data.filter(
          com =>
            getMonthAndYear(new Date(com.subscription_buyed)) === currentMonth[0] &&
            getMonthAndYear(new Date(com.subscription_buyed))[1] === currentMonth[1]
        );
        const currentMonthRewards = sumAmountCommissions(currentMonthCommissions)[0];

        const lastMonth = getMonthAndYear(new Date());
        const lastMonthCommissions = resp.data.filter(
          com =>
            getMonthAndYear(new Date(com.subscription_buyed)) === lastMonth[0] &&
            getMonthAndYear(new Date(com.subscription_buyed))[1] === lastMonth[1]
        );
        const lastMonthRewards = sumAmountCommissions(lastMonthCommissions)[0];
        let porcent = lastMonthRewards <= 0 ? 0 : (currentMonthRewards / lastMonthRewards) * 100;
        porcent = porcent < 100 ? Number(porcent * -1) : Number(porcent - 100);
        setPorcent(porcent);
      }
    })();
  }, []);

  return (
    <>
      <CardLayout style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Typography align='center' fontWeight={600} variant='h5' color='textPrimary'>
          {t('generalInfo.totalIncome.title')}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 4 }}>
          <Box>
            <Typography fontWeight={600} variant='h3' color='textPrimary'>
              ${gains[0]}
            </Typography>
            <Typography variant='caption' color='textPrimary'>
              {porcent < 0 ? t('generalInfo.totalIncome.down') : t('generalInfo.totalIncome.up')}{' '}
              {porcent} {t('generalInfo.totalIncome.lastMonth')}
            </Typography>
          </Box>
          <Box>
            <Image
              src={
                porcent < 0
                  ? '/images/graphics/dashboard/generalInfo/redChart.svg'
                  : '/images/graphics/dashboard/generalInfo/greenChart.svg'
              }
              alt='greenChart'
              width={200}
              height={100}
            />
          </Box>
        </Box>
      </CardLayout>
    </>
  );
}
