import { Alert, Button, Box, Divider, Grid, Typography } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import PermanentDrawerLeft from '../Layouts/Dashboard/User';
import FormMetatrader from './FormMetatrader';
import GeneralInfo from './GeneralInfo';
import UpcomingPayment from './UpcomingPayment';
import BannerDashboard from '../BannerDashboard';
import GetPremiumBackdrop from '../GetPremium';
import validatePremiumAccess from '@/utils/validatePremiumAccess';
import { useTranslation } from 'react-i18next';
import BannerRange from './BannerRange';
import { LoadingButton } from '@mui/lab';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import styled from '@emotion/styled';

const colors = {
  premium: {
    primary: '#F7A429',
  },
  basic: {
    primary: '#017FC7',
  },
};

const NextPaymentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#FFEBE1',
  display: 'flex',
  gap: '1rem',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem',
  borderRadius: '1rem',
  marginTop: '1rem',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

export default function UserDashboard() {
  const { t } = useTranslation('dashboard');
  const [typeAccessUser, setTypeAccessUser] = useState(null);
  const router = useRouter();
  const [currentSession, setCurrentSession] = useState(null);
  const [showRenewMembership, setShowRenewMembership] = useState(false);

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem('sessionMitox'));

    setCurrentSession(session);
    setTypeAccessUser(validatePremiumAccess(session));
  }, []);

  const redirectToProfile = () => {
    router.push('/profile');
  };

  const NextPayment = () => {
    const date = currentSession.user.next_pay_commission_plan_renovation;
    const splitDate = date.split('-');
    
    if(date){
      return (
        <NextPaymentContainer>
          <Typography variant='h5' fontWeight={600} color='textPrimary'>
            Tu suscripción se vence:
          </Typography>
          <Box display='flex' gap='1rem'>
            <Grid item display='flex' flexDirection='column' alignItems='center'>
              <Box
                sx={{
                  backgroundColor: 'white',
                  px: 2,
                  py: 1,
                  borderRadius: '.5rem',
                  fontSize: '2rem',
                }}
              >
                {splitDate[2]}
              </Box>
              <Box sx={{ mt: 1, color: '#4A4A4A' }}>Día</Box>
            </Grid>
            <Grid item display='flex' flexDirection='column' alignItems='center'>
              <Box
                sx={{
                  backgroundColor: 'white',
                  px: 2,
                  py: 1,
                  borderRadius: '.5rem',
                  fontSize: '2rem',
                }}
              >
                {splitDate[1]}
              </Box>
              <Box sx={{ mt: 1, color: '#4A4A4A' }}>Mes</Box>
            </Grid>
            <Grid item display='flex' flexDirection='column' alignItems='center'>
              <Box
                sx={{
                  backgroundColor: 'white',
                  px: 2,
                  py: 1,
                  borderRadius: '.5rem',
                  fontSize: '2rem',
                }}
              >
                {splitDate[0]}
              </Box>
              <Box sx={{ mt: 1, color: '#4A4A4A' }}>Año</Box>
            </Grid>
          </Box>
        </NextPaymentContainer>
      );
    }
  };

  if (currentSession === null) {
    return <></>;
  } else if (currentSession !== null) {
    if (typeAccessUser[0] === 'premium') {
      return !showRenewMembership ? (
        <>
          <PermanentDrawerLeft session={currentSession}>
            <Grid
              container
              sx={{
                width: '100%',
                my: 2,
              }}
            >
              <Grid item xs={12}>
                <BannerDashboard />
              </Grid>
            </Grid>
            {currentSession.user.wallet_address_pay_commission ? (
              <></>
            ) : (
              <Alert
                onClick={redirectToProfile}
                sx={{ my: 2, cursor: 'pointer' }}
                severity='warning'
              >
                {t('alertMessage')}
              </Alert>
            )}

            {(currentSession.user.membership_status === 'Expired' ||
              currentSession.user.id_range === null ||
              currentSession.user.id_range === 0) && (
              <Alert sx={{ my: 2, '& .MuiAlert-message': { width: '100%' } }} severity='warning'>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                  gap={4}
                  width='100%'
                >
                  <Grid container>
                    <Grid xs={12} md={10}>
                      <span>{t('expiredMembership.statusMessage')}</span>
                      <br />
                      <Grid container>
                        <Grid xs={12} md={4}>
                          <strong>
                            {t('expiredMembership.lastPay')}
                            {': '}
                            {currentSession.user.last_pay_commission_plan_renovation}.
                          </strong>
                        </Grid>
                        <Grid xs={12} md={4}>
                          <strong>
                            {t('expiredMembership.duration')}
                            {': '}
                            {currentSession.user.months_pay_commission_plan_renovation}{' '}
                            {t('expiredMembership.months')}.
                          </strong>
                        </Grid>
                        <Grid xs={12} md={4}>
                          <strong>
                            {t('expiredMembership.nextPay')}
                            {': '}
                            {currentSession.user.next_pay_commission_plan_renovation}.
                          </strong>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <LoadingButton
                        loading={false}
                        onClick={() => setShowRenewMembership(true)}
                        sx={{
                          backgroundColor: colors.basic.primary,
                        }}
                        variant='contained'
                        startIcon={<WorkspacePremiumIcon />}
                      >
                        {t('expiredMembership.buttonMessage')}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Box>
              </Alert>
            )}

            <GeneralInfo 
              openBuyButton={()=>{
                setShowRenewMembership(true);
              }} 
            />
            <UpcomingPayment />
            <NextPayment />

            <Divider sx={{ my: 2 }} />
            <FormMetatrader session={currentSession} />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {/* <Image
                src='/images/graphics/mainLogo.png'
                width={357}
                height={150}
                alt='logo'
              /> */}
              <BannerRange />
            </Box>
          </PermanentDrawerLeft>
        </>
      ) : (
        <>
          <PermanentDrawerLeft session={currentSession}>
            <GetPremiumBackdrop renewing={true} />
            <Box width='100%' display='flex' justifyContent='center' marginTop={2}>
              <Button
                onClick={() => setShowRenewMembership(false)}
                variant='contained'
                color='primary'
              >
                {t('formMetaTrader.modal.cancel')}
              </Button>
            </Box>
          </PermanentDrawerLeft>
        </>
      );
      // eslint-disable-next-line no-constant-condition
    } else if (typeAccessUser[0] === 'no premium') {
      return (
        <>
          <PermanentDrawerLeft session={currentSession}>
            <GetPremiumBackdrop renewing={true} />
          </PermanentDrawerLeft>
        </>
      );
    }
  }
}
