export default function getMonthAndYear(fecha, last = -1) {
  const currentDate = new Date(fecha);
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  let lastMonth = currentMonth - last;
  let lastYear = currentYear;
  if (currentMonth === 0) {
    // Si es enero, el mes pasado es diciembre del año anterior
    lastMonth = 11; // diciembre = 11
    lastYear = currentYear - 1;
  }
  return [lastMonth, lastYear];
}
