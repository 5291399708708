import { axiosBase } from './axiosBase';

const getAllCommissions = async token => {
  const resp = await axiosBase('subscription/commissions', 'GET', null, token);
  return resp;
};

const getCommisionsAdminPage = async token => {
  const resp = await axiosBase('subscription/commissionsAll', 'GET', null, token);
  return resp;
};

export { getAllCommissions, getCommisionsAdminPage };
