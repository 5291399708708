import { getAllRanges } from '@/API/ranges';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { ranges } from '@/utils/bannerRanges';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

const dummyRange = {
  id: 0,
  level: 0,
  name: 'NO MEMBER',
  description: 'ADQUIERE ACCESO A LA PÁGINA Y OBTEN LOS BENEFICIOS DE LOS RANGOS',
  total_user_amount_general_accumulated: 0,
  pin_image: null,
  rangesRequired: [],
};

export default function BannerRange() {
  const [currentRange, setCurrentRange] = useState(dummyRange);
  const [currentImageRange, setCurrentImageRange] = useState(null);

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem('sessionMitox'));
    (async () => {
      const resp = await getAllRanges(session.token);
      if (resp.status === 200) {
        const idUserRange = session.user.id_range;
        if (idUserRange) {
          const currentRange = resp.data.find(range => range.id === idUserRange);
          setCurrentRange(currentRange);
          const currentImageBanner = ranges.find(image => image.id === idUserRange);
          console.log({ currentImageBanner });
          setCurrentImageRange(currentImageBanner);
        } else {
          setCurrentImageRange(null);
        }
      }
    })();
  }, []);

  const supportNumber = '573145427343';
  const redirectSupport = () => {
    // https://api.whatsapp.com/send/?phone=573114688342&text&type=phone_number&app_absent=0
    window.open(
      `https://api.whatsapp.com/send/?phone=${supportNumber}&text=Hola+quiero+reclamar+mi+premio+de+rango+${currentRange.name}`,
      '_BLANK'
    );
    // &text=Hola%2C+¿cómo+estás%3F&type=phone_number&app_absent=0
  };

  if (currentRange.id === 0) {
    return <></>;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Image
          style={{
            borderRadius: '2rem',
            cursor: currentRange.id !== 1 && 'pointer',
            width: '100%',
          }}
          onClick={currentRange.id === 1 ? null : redirectSupport}
          src={currentImageRange ? currentImageRange.img : '/images/graphics/mainLogo.png'}
          alt='rangeBanner'
          width={600}
          height={120}
        />
        {currentRange.id <= 1 ? (
          <>
            <Typography variant='body1' color='textPrimary' sx={{ mt: 1 }} align='center'>
              Sigue subiendo de nivel para tener acceso a las recompensas
            </Typography>
          </>
        ) : (
          <>
            <Typography sx={{ mt: 1 }} align='center' variant='body1' color='caption'>
              Eres usuario {currentRange.name}, click en la imagen para reclamar tu premio
            </Typography>
          </>
        )}
      </Box>
    </>
  );
}
