export default function validatePremiumAccess(session) {
  if (!session.user.userSubscription) {
    return ['no premium', 'no courses'];
  }

  let premium = false;
  let courses = false;

  for (let i = 0; i < session.user.userSubscription.length; i++) {
    const subscription = session.user.userSubscription[i];
    if (subscription.status_pay === 1) {
      premium = true;
    }
    if (subscription.academy_access === 1) {
      courses = true;
    }
  }

  if (premium && courses) {
    return ['premium', 'courses'];
  } else if (premium && !courses) {
    return ['premium', 'no courses'];
  } else if (!premium && courses) {
    return ['no premium', 'courses'];
  } else {
    return ['no premium', 'no courses'];
  }
}
