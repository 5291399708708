import { changeMetaTrader } from '@/API/metaTrader';
import { theme } from '@/utils/theme';
import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  Container,
  Typography,
  DialogActions,
  Button,
  TextField,
  Grid,
  Autocomplete,
  Alert,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function MetaTraderFormFill({ open, handleClose }) {
  const { t } = useTranslation('dashboard');

  const metatraderLevelRisk = [
    t('formMetaTrader.modal.mediumRisk'),
    t('formMetaTrader.modal.lowRisk'),
  ];
  const metatraderServers = ['MEXAtlantic-Real', 'MEXAtlantic-Real-2', 'MEXAtlantic-Real-3'];

  const router = useRouter();
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState({ show: false, message: '' });
  const [input, setInput] = useState({
    idMetatrader: '',
    metatraderLevelRisk: '',
    password: '',
    metatraderPassword: '',
    server: '',
  });
  const [currentSession, setCurrentSession] = useState(null);

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem('sessionMitox'));
    setCurrentSession(session);
    session && session.user.id_metatrader
      ? setInput({
          idMetatrader: session.user.id_metatrader ?? '',
          metatraderLevelRisk:
            (session.user.metatrader_level_risk === 0 && t('formMetaTrader.modal.mediumRisk')) ||
            (session.user.metatrader_level_risk === 1 && t('formMetaTrader.modal.lowRisk')),
          password: '',
          metatraderPassword: '',
          server: session.user.metatrader_server ?? '',
        })
      : setInput({
          idMetatrader: '',
          metatraderLevelRisk: '',
          password: '',
          metatraderPassword: '',
          server: session.user.metatrader_server ?? '',
        });
  }, []);

  const handleSubmitForm = async e => {
    e.preventDefault();
    setLoadingCreate(true);
    console.log(currentSession);
    let metaRisk;
    switch (input.metatraderLevelRisk) {
      case t('formMetaTrader.modal.lowRisk'):
        metaRisk = 0;
        break;
      case t('formMetaTrader.modal.mediumRisk'):
        metaRisk = 1;
        break;
      default:
        break;
    }
    const body = {
      password: input.password,
      idMetatrader: input.idMetatrader,
      metatraderLevelRisk: metaRisk,
      metatraderPassword: input.metatraderPassword,
      metatraderServer: input.server,
    };
    console.log({ body });
    // const resp = await changeWallet()
    const resp = await changeMetaTrader(body, currentSession.token);

    if (resp.status === 200) {
      const session = JSON.parse(localStorage.getItem('sessionMitox'));
      session.user.id_metatrader = body.idMetatrader;
      session.user.metatrader_level_risk = body.metatraderLevelRisk;
      session.user.metatrader_password = body.metatraderPassword;
      session.user.metatrader_server = body.metatraderServer;
      localStorage.setItem('sessionMitox', JSON.stringify(session));
      setCurrentSession(session);
      setLoadingCreate(false);
      handleClose();
      // router.reload(window.location.pathname);
    } else if (resp.status === 201) {
      setLoadingCreate(false);
      setErrorUpdate({ show: true, message: resp.data.message });
    }
  };

  const handleCancelSave = e => {
    e.preventDefault();
    currentSession.user.id_metatrader
      ? setInput({
          idMetatrader: currentSession.user.id_metatrader ?? '',
          metatraderLevelRisk:
            (currentSession.user.metatrader_level_risk === 0 &&
              t('formMetaTrader.modal.lowRisk')) ||
            (currentSession.user.metatrader_level_risk === 1 &&
              t('formMetaTrader.modal.mediumRisk')),
          password: '',
          metatraderPassword: currentSession.user.metatrader_password ?? '',
          server: currentSession.user.metatrader_server ?? '',
        })
      : setInput({
          idMetatrader: '',
          metatraderLevelRisk: '',
          password: '',
          metatraderPassword: '',
          server: '',
        });
    handleClose();
  };

  const handleChange = e => {
    const { name, value } = e.target;

    setInput({
      ...input,
      [name]: value,
    });
  };
  const changeSelect = selectedServer => {
    setInput({
      ...input,
      server: selectedServer,
    });
  };

  return (
    <>
      <Dialog maxWidth='sm' fullWidth open={open} onClose={handleClose}>
        <Container maxWidth='xl' fullWidth>
          <Grid container spacing={0}>
            <Typography sx={{ my: 2 }} variant='h4' fontWeight={600} color='textPrimary'>
              MetaTrader4 Form
            </Typography>

            <Grid sx={{ my: 2 }} item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Server</InputLabel>
                <Select onChange={e => changeSelect(e.target.value)} value={input.server}>
                  {metatraderServers.map(server => (
                    <MenuItem key={server} value={server}>
                      {server}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid sx={{ my: 2 }} item xs={12}>
              <TextField
                fullWidth
                name='idMetatrader'
                label='ID MetaTrader4'
                value={input.idMetatrader}
                onChange={handleChange}
              />
            </Grid>
            <Grid sx={{ my: 2 }} item xs={12}>
              <Autocomplete
                value={input.metatraderLevelRisk}
                onChange={(e, newVal) => {
                  setInput({ ...input, metatraderLevelRisk: newVal });
                }}
                onInputChange={(e, newInput) => {
                  setInput({ ...input, metatraderLevelRisk: newInput });
                }}
                options={metatraderLevelRisk}
                renderInput={params => <TextField {...params} label='Risk' />}
              />
            </Grid>
            <Grid sx={{ my: 2 }} item xs={12}>
              <TextField
                fullWidth
                name='metatraderPassword'
                type='password'
                label='Metatrader Password'
                value={input.metatraderPassword}
                onChange={handleChange}
              />
            </Grid>
            <Grid sx={{ my: 2 }} item xs={12}>
              <TextField
                fullWidth
                name='password'
                type='password'
                label='Your Password of Mitox'
                value={input.password}
                onChange={handleChange}
              />
            </Grid>
            {errorUpdate.show && (
              <Alert fullWidth sx={{ width: '100%' }} severity='error'>
                {errorUpdate.message}
              </Alert>
            )}
          </Grid>
          <Link
            style={{
              color: theme.palette.primary.main,
              textDecoration: 'none',
            }}
            target='_BLANK'
            href={'/MITOX_GROUP_SAS.pdf'}
          >
            {t('formMetaTrader.modal.TyC')}
          </Link>
          <DialogActions sx={{ mb: 2 }}>
            <LoadingButton
              loading={loadingCreate}
              disabled={
                Object.values(input).some(val => val === '') ||
                ![t('formMetaTrader.modal.mediumRisk'), t('formMetaTrader.modal.lowRisk')].includes(
                  input.metatraderLevelRisk
                )
              }
              onClick={handleSubmitForm}
              fulWidth
              variant='contained'
              color='primary'
            >
              {t('formMetaTrader.modal.save')}
            </LoadingButton>
            <Button fulWidth onClick={handleCancelSave} variant='contained' color='primary'>
              {t('formMetaTrader.modal.cancel')}
            </Button>
          </DialogActions>
        </Container>
      </Dialog>
    </>
  );
}
