import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import Counter from './Counter';
import { useEffect, useState } from 'react';
import { getAllCommissions } from '@/API/commissions';
import sumCommissionByStatus from '@/utils/sumAmountCommissions';
import { useTranslation } from 'react-i18next';

const UpcomingContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#FFEBE1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem',
  borderRadius: '1rem',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

export default function UpcomingPayment() {
  const {t} = useTranslation('dashboard')
  const [upcomingPay, setUpcomingPay] = useState(0);

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem('sessionMitox'));

    (async () => {
      const resp = await getAllCommissions(session.token); // get all commissions related to currentUser
      if (resp.status === 200) {
        const commisionToReceive = sumCommissionByStatus(resp.data) ?? 0;
        setUpcomingPay(commisionToReceive[1]);
      }
    })();
  }, []);

  return (
    <>
      <Grid item xs={12} sx={{ mt: 4 }}>
        <UpcomingContainer>
          <Box>
            <Typography variant='h5' fontWeight={600} color='textPrimary'>
              {t('upcomingPayment.title')}
            </Typography>
            {/* <Box item xs={12} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}> */}
              <Typography align='center' fontWeight={600} variant='h3' color='textPrimary'>
                ${upcomingPay}
              </Typography>
            {/* </Box> */}
          </Box>
          <Box>
            <Counter timePay={'19:00:00'} />
          </Box>
        </UpcomingContainer>
      </Grid>
    </>
  );
}
