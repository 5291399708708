import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import UserDashboard from '@/components/Dashboard/User';
import cookie from 'cookie';

export default function Page(_props) {
  return (
    <>
      <UserDashboard />
    </>
  );
}

export async function getServerSideProps(context) {
  const { req } = context;
  const cookies = cookie.parse(req.headers.cookie || '');
  const language = cookies.language || 'en';

  return {
    props: {
      ...(await serverSideTranslations(language, ['common', 'getPremiumBackdrop','dashboard'])),
    },
  };
}
