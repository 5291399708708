import { getAllReferrals, getReferrals } from '@/API/referrals';
import CardLayout from './CardLayout';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { useEffect, useMemo, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import getMonthAndYear from '@/utils/getMonthAndYear';
import { theme } from '@/utils/theme';
import { useTranslation } from 'react-i18next';

function ShowData({ title, data, isVIP }) {
  return (
    <>
      <Grid spacing={2} display='flex' flexDirection={isVIP ? 'row' : 'column'} alignItems='center' gap={isVIP ? 0 : 2}>
        <Grid item xs={12}>
          <Typography align='center' variant='body1' color='textPrimary'>
            {title}
          </Typography>
        </Grid>
        <Grid alignItems='center' item xs={12}>
          <Box
            sx={{
              border: `5px solid ${theme.palette.primary.main}`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '4rem',
              height: '4rem',
              m: 'auto',
              borderRadius: '50%',
            }}
          >
            <Typography fontWeight={600} align='center' variant='h4' color='textPrimary'>
              {data}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default function ReferFriends() {
  const { t } = useTranslation('dashboard');
  const [currentSession, setCurrentSession] = useState(null);
  const [referralsInfo, setReferralsInfo] = useState({
    today: 0,
    direct: 0,
    indirect: 0,
    total: 0,
  });

  const isVIP = useMemo(
    () =>
      !currentSession?.user?.userSubscription?.some(
        sub => sub.subscription === 'Premium Plus' && sub.status_pay === 1
      ),
    [currentSession]
  );

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem('sessionMitox'));

    setCurrentSession(session);

    if (session) {
      setReferralsInfo({
        today: session.user.getTotalDirectUsersToday || 0,
        direct: session.user.getTotalDirectUsers || 0,
        indirect: session.user.getTotalDownUsers || 0,
        total: session.user.getTotalUsers || 0,
      });
    }
  }, []);

  return (
    <>
      <CardLayout>
        <Typography align='center' fontWeight={600} variant='h5' color='textPrimary'>
          {t('generalInfo.referFriends.title')}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={isVIP ? 12 : 3}>
              <Paper variant='elevation' sx={{ pb: 1 }}>
                <ShowData
                  isVIP={isVIP}
                  title={t('generalInfo.referFriends.todayTitle')}
                  data={referralsInfo.today}
                />
              </Paper>
            </Grid>
            <Grid item xs={isVIP ? 12 : 3}>
              <Paper variant='elevation' sx={{ pb: 1 }}>
                <ShowData
                  isVIP={isVIP}
                  title={t('generalInfo.referFriends.direct')}
                  data={referralsInfo.direct}
                />
              </Paper>
            </Grid>
            <Grid item xs={isVIP ? 12 : 3}>
              <Paper variant='elevation' sx={{ pb: 1 }}>
                <ShowData
                  isVIP={isVIP}
                  title={t('generalInfo.referFriends.indirect')}
                  data={referralsInfo.indirect}
                />
              </Paper>
            </Grid>
            <Grid item xs={isVIP ? 12 : 3}>
              <Paper variant='elevation' sx={{ pb: 1 }}>
                <ShowData
                  isVIP={isVIP}
                  title={t('generalInfo.referFriends.totalTitle')}
                  data={referralsInfo.total}
                />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </CardLayout>
    </>
  );
}
